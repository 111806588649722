'use client';

import { useState, useRef } from 'react';
import { Image } from '@akinon/next/components';
import { Icon, Link } from '@theme/components';
import { useWindowSize } from '@theme/hooks/use-window-size';

export default function SpecialDealsContent({ data }) {
  const [isPlaying, setIsPlaying] = useState(false);
  const videoRef = useRef(null);
  const { width } = useWindowSize();
  const isMobile = width < 768;

  const toggleVideoPlayback = () => {
    if (videoRef.current) {
      const video = videoRef.current as HTMLVideoElement;

      isPlaying ? video.pause() : video.play();
      setIsPlaying(!isPlaying);
    }
  };

  const file = isMobile
    ? data?.kwargs?.value?.mobile_file?.url
    : data?.kwargs?.value?.file.url;

  return (
    <>
      <div className="relative hidden h-[233px] overflow-hidden rounded-2xl lg:flex lg:h-[414px]">
        {data?.kwargs?.value?.file.url?.includes('.mp4') ? (
          <div className="relative h-[233px] w-[255px] rounded-2xl lg:h-[414px]">
            <video
              ref={videoRef}
              className="h-full w-full rounded-2xl object-cover"
              width={255}
              height={414}
              src={data?.kwargs?.value?.file.url}
              onEnded={() => setIsPlaying(false)}
              muted
            />
            {isPlaying ? (
              <div
                className="absolute bottom-0 start-0 flex cursor-pointer items-center justify-center opacity-70 hover:scale-125 hover:opacity-100"
                onClick={toggleVideoPlayback}
              >
                <Icon
                  name="video-pause"
                  size={40}
                  className="z-10 text-white"
                />
              </div>
            ) : (
              <div
                className="absolute inset-0 flex cursor-pointer items-center justify-center"
                onClick={toggleVideoPlayback}
              >
                <div className="absolute inset-0 rounded-2xl bg-[#838383] opacity-40"></div>
                <Icon name="video-play" size={40} className="z-10 text-white" />
              </div>
            )}
          </div>
        ) : (
          <Link href={data?.value?.redirect_url}>
            <Image
              className="h-full rounded-2xl"
              src={data?.kwargs?.value?.file?.url}
              alt={data?.value?.file_alt_text}
              aspectRatio={255 / 414}
              sizes="(max-width: 768px) 100vw, (max-width: 1023px) 50vw, 33vw"
              fill
            />
          </Link>
        )}
      </div>
      <div className="relative flex h-[233px] overflow-hidden rounded-2xl lg:hidden lg:h-[414px]">
        {data?.kwargs?.value?.mobile_file?.url?.includes('.mp4') ? (
          <div className="relative h-[233px] w-[255px] rounded-2xl lg:h-[414px]">
            <video
              ref={videoRef}
              className="h-full w-full rounded-2xl object-cover"
              width={255}
              height={414}
              src={data?.kwargs?.value?.mobile_file?.url}
              onEnded={() => setIsPlaying(false)}
              muted
            />
            {isPlaying ? (
              <div
                className="absolute bottom-0 start-0 flex cursor-pointer items-center justify-center opacity-70 hover:scale-125 hover:opacity-100"
                onClick={toggleVideoPlayback}
              >
                <Icon
                  name="video-pause"
                  size={40}
                  className="z-10 text-white"
                />
              </div>
            ) : (
              <div
                className="absolute inset-0 flex cursor-pointer items-center justify-center"
                onClick={toggleVideoPlayback}
              >
                <div className="absolute inset-0 rounded-2xl bg-[#838383] opacity-40"></div>
                <Icon name="video-play" size={40} className="z-10 text-white" />
              </div>
            )}
          </div>
        ) : (
          <Link href={data?.value?.redirect_url} className="w-full">
            <Image
              imageClassName="rounded-2xl object-cover h-full"
              className="h-full rounded-2xl"
              src={data?.kwargs?.value?.mobile_file?.url}
              alt={data?.value?.file_alt_text}
              aspectRatio={255 / 414}
              sizes="(max-width: 768px) 100vw, (max-width: 1023px) 50vw, 33vw"
              fill
            />
          </Link>
        )}
      </div>
    </>
  );
}
